import type {ReactElement} from 'react';
import {useSeoConfig} from '@uxu/design-system/hooks/useSeoConfig';
import {LayoutFull} from '@uxu/design-system/components/layout/layoutFull';
import {footerConfig, headerMenuConfig} from "../config";
import {SectionHello} from "../components";


function Index(): ReactElement {
    const seo = useSeoConfig({});
    return (
        <LayoutFull
            footer={footerConfig} headerMenu={headerMenuConfig}
            seo={seo}
        >
            <SectionHello />
        </LayoutFull>
    );
};

export default Index;
